.whatsappfooter {
  width: 64px;
  height: 64px;
  border-radius: 12px;
  padding: 32px;
  background: #63AB45;
  z-index: 100;
  position:fixed;
  top: 20px;
  right: 20px;
}
.whatsappfooter a svg{
  width: 32px;
  height: 32px;
}
.whatsappfooter{
  -webkit-animation: glowing 1s ease-in-out infinite alternate;
  -moz-animation: glowing 1s ease-in-out infinite alternate;
  animation: glowing 1s ease-in-out infinite alternate;
}
@keyframes glowing {
  from {
    box-shadow: 0 0 2px rgb(255, 255, 255,0.8), 0 0 4px rgb(255, 255, 255,0.4), 0 0 6px rgb(7, 223, 7,0.4), 0 0 8px rgb(150, 236, 132,0.4), 0 0 10px #f1f3f2, 0 0 12px rgb(19, 163, 43,0.4), 0 0 14px #f1f3f2;
  }
  to {
    box-shadow: 0 0 4px rgb(255, 255, 255,1.0), 0 0 6px rgb(245, 245, 245,0.6), 0 0 8px rgb(242, 245, 240,0.6), 0 0 10px rgb(98, 243, 117,0.6), 0 0 12px rgb(3, 51, 23,0.6), 0 0 14px rgb(13, 66, 24,0.6), 0 0 16px #f1f3f2;
  }
}

/** medium screen **/
@media screen and (min-width: 768px){
  .whatsappfooter {
    top: auto;
    bottom: 20px;
  }
}
/** large screen **/
@media screen and (min-width: 1024px){
  .whatsappfooter {
    width: 100px;
    height: 100px;
    padding: 50px;
    border-radius: 24px;
  }
  .whatsappfooter a svg{
    width: 50px;
    height: 50px;
  }
}