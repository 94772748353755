.gallery{
    background:#f6fbf4;
}
.gallery .gallerycontainer h1{
    font-weight: 700;
    font-size: 32px;
}
.gallery .gallerycontainer h2{
    font-weight: 700;
    font-size: 27px;
    line-height: 36px;
}
.gallery .gallerytwo .spotbox{
    width: 262px;
    height: 278px;
    background: #FFFFFF;
    border: 1px solid rgba(34, 34, 34, 0.15);
    border-radius: 12px;
}
.gallery .gallerytwo .spotbox img{
    width: 262px;
    border: 1px solid rgba(34, 34, 34, 0.15);
    object-fit: cover;
    height: 220px;
    border-radius:12px 12px 0 0;
}
.gallery .gallerytwo .spotbox p{
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
}

