.footer{
    background: #1D231F;
    background-size:cover;
    color: #FFFFFF;
  }
  .footer .footerlogo {
    padding: 5rem 0 5rem 0;
  }
  .footer .footerlogo .img {
    width: 100%;
    height: 100%;
  }
  .footer .footerlogo .img img {
    max-width: 100%;
    max-height: 80px;
  }
  .footer .footerlogo h6{
     font-weight: 500;
     font-size: 20px;
     opacity: 0.8;
  }
   .footerapp a{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: #262D28;
  }
  .contact button{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.16);
  }
  .footercontact h3{
    font-weight: 700;
    font-size: 32px;
  }
  .contact h4{
    font-size: 16px;
    color: #A7A7A7;
    font-weight: 500;
  }
  .contact h3{
    font-weight: 500;
    font-size: 16px;
  }
   .travel{
    max-width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    height:auto;
  }
  .travel .travellight {
    min-width: 60px;
    min-height: 60px;
    background: #63AB45;
    box-shadow: 0px 10px 60px rgba(99, 171, 69, 0.61);
    border-radius: 29.7px;
  }
  .travel .lighttwo {
    min-width: 60px;
    min-height: 60px;
    background: #F7921E;
    box-shadow: 0px 10px 60px rgba(247, 146, 30, 0.54);
    border-radius: 29.7px;
  }
  .travel h5{
    font-size: 26px;
  }
  