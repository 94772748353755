.heroBanner{
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFFFFF;
  min-width: 100%;
  min-height: 100vh;
  position: relative;
}
.heroBanner .video{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
}
.heroBanner .video::after{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}
.heroBanner .video iframe{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  background-color: #000;
}
@media (min-aspect-ratio: 16/9) {
  .heroBanner .video iframe {
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .heroBanner .video iframe {
    width: 100vw;
  }
}
.heroBanner .content{
  padding: 180px 0;
}
.heroBanner .bannerTitle{
  padding-bottom: 0;
}
.heroBanner h3{
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.02em;
}
.heroBanner .bannerTitle h1{
  font-weight: 900;
  font-size: 48px;
  letter-spacing: -0.02em;
}
.heroBanner .bannerTitle p{
  font-weight: 400;
  font-size: 20px;
}
.heroBanner .content-box{
  background: #FFFFFF;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: black;
}
.heroBanner .content-box p{
  font-weight: 500;
  font-size: 20px;
  color: #1C231F;
}
.heroBanner .content-box p span{
  color: #63AB45;
}
.heroBanner .content-box button{
  height: 56px;
  width: 170px;
  background: #63AB45;
  border-radius: 8px;
  color: #FFFFFF;
}
.heroBanner .content-box button:hover{
  background:#1C231F ;
}

.section-one{
  color: var(--text2);
  background-color: #f3f8f6;
}
.section-one h2{
  top: -160px;
  font-size: 24px;
  color: #FFFFFF;
  width: 100%;
  left: 0;
  font-weight: 500;
  text-align: center;
}
.section-one .box-container{
  margin-bottom: -56px;
}
.section-one .box-container .box-cover{
  min-height: 240px;
  max-width: 260px;
  width: 100%;
  overflow: hidden;
  margin-top: -60px;
  margin-bottom: 60px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: inset 0px 0px 24px rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 1.5rem 2rem 3rem 2rem;
}
.section-one .box-container .box-cover:hover{
  background: #63AB45;
  color:#FFFFFF;
}
.section-one .title-head p{
  font-size: 42px;
  font-weight: 700;
}
.section-one .box-container .box-cover h3{
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
}
.section-one .box-container .box-cover p{
  font-size: 16px;
  line-height: 1.65;
  opacity: 0.8;
}

.section-two {
  color: var(--text2);
  background-color: #f3f8f6;
}
.section-two .title p {
  background: rgba(99, 171, 69, 0.2);
  border-radius: 3px;
  font-size: 16px;
  color: #63AB45;
  padding: 0.5rem 2rem;

}
.section-two .title h2{
  font-weight: 700;
  font-size: 48px;
  letter-spacing: -0.02em;
  text-align: center;
}
.section-two .place-container {
  overflow: visible;
}
.section-two .place-container .place-box{
   max-width: 310px;
   width: 100%;
   height: 353px;
   border-radius: 12px;
   background-color: gray;
   transform-origin: center;
   transform: scale(1);
   transition: transform 0.2s ease-in-out;
}
.section-two .place-container .place-box:hover{
  transform: scale(1.05);
}
.section-two .place-container .place-box img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
  position:relative ;
}
.section-two .place-container .place-box .shadow{
  color: #FFFFFF;
  font-weight: 700;
  font-size: 24px;
  width: 100%;
  border-radius: 12px;
  padding: 2rem 1rem;
  text-align: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%); 
}
.section-two .place-container .place-box .tag{
  color: white;
  background: #F7921E;
  width: 94px;
  height: 30px;
  border-radius: 8px;
  top: 20px;
  right: 20px;
}
.section-two .place-container .place-box .place-head{
  width: 100%; 
}

.section-three .title span {
  background: rgba(99, 171, 69, 0.1);
  width: 260px;
  height: 40px;
  border-radius: 6px;
  font-size: 16px;
  color: #63AB45;
}
.section-three .title  h2{
  font-weight: 700;
  font-size: 48px;
}
.section-three .title  p{
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #82828A;
  
}
.section-three .about-image img { 
  max-width:500px;
  width: 100%;
  transform: rotate(0deg);
  object-fit: cover;  
  
}

.section-four {
  color:var(--text1);
  width: 100%;
  height: 860px;
  max-height: 100vh;
  position: relative;
  overflow: hidden;
}
.section-four .road{
  background: url('/src/images/road.png');
  background-repeat: repeat-x;
  background-size: auto 100%;
  width: 1000%;
  height: 100%;
  position: absolute;
  top:0;
  animation: road 30s infinite linear reverse;
}
@keyframes road {
  0%{
    left: -500%;
  }
  100%{
    left: 0%;
  }
}
.section-four .title{
  position: absolute;
  width: 100%;
  height: 50%;
  z-index: 1;
  font-size: 32px;
  font-weight: 700;
  color: #FFFFFF;
  text-align: center;
  opacity: 0.8;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}
.section-four h2.title{
  top: 10%;
}
.section-four p.title{
  top: 40%;
}
.section-four .car{
  position: absolute;
  height: 30%;
  z-index: 2;
}
.section-four .car.one{
  top:20%;
  animation: carone 6s infinite linear;
}
.section-four .car.two{
  top:50%;
  animation: carone 6s infinite linear;
  animation-delay: 2s;
}
.section-four .car.three{
  top:35%;
  animation: carone 6s infinite linear;
  animation-delay: 4s;
}
@keyframes carone {
  0%{
    left: -50%;
    transform: translatex(-100%);
  }
  100%{
    left: 150%;
    transform: translatex(100%);
  }
}
.car{
  left: 100vw;
  top: 20%;
}

.cartwo{
  width: 320px;
  max-height: 190px;
  animation: car1 4s infinite linear reverse;
}
@keyframes car1 {
  100%{
    transform: translate(-100vw);
  }
}
.car2{
  left: 90%;
  top: 45%; 
}
.carthree{
  width: 320px;
  min-height: 190px;
  animation: car2 8s infinite linear reverse;
}
.car3{
  left:90%;
  top: 60%;
  
}
@keyframes car2 {
  100%{
    transform: translate(-100vw);
  }
}

.bodyOffer .offer-container  h2{
  font-weight: 900;
  font-size: 48px;
  letter-spacing: -0.6px;
}
.bodyOffer .offer-container  h3{
  font-weight: 600;
  font-size: 44px;
 
}
.bodyOffer .offer-container .offerbox{
  width: 100%;
  height: 100%;
  
}

.section-five { 
  color: #1C231F; 
}
.section-five .videocontainer { 
  border-radius: 12px;
  background-color: #1D231F;
  overflow: hidden;
  box-shadow: inset 0px 0px 24px rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.16);
  padding: 16px;
}
.section-five .videocontainer video { 
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  background-color: #000;
}

.section-five .videocontainer .videocover {
  width: 100%;
  height: 100%;
}
.section-five .videocontainer span{
  background: rgba(99, 171, 69, 0.1);
  border-radius: 6px;
  font-size: 18px;
  color: #63AB45;
  font-weight: 500;
}
.section-five .videocontainer h2{
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  color: #FFFFFF;
  line-height: 1.35;
}
.section-five .videocontainer .videobox{
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}
.section-five .videocontainer a{
  width: 100%;
  background: #63AB45;
  border-radius: 8px;
  color: #FFFFFF;
}
.section-six{
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  padding: 0 16px;
  margin-bottom: -64px;
}
.section-six .visitbox{
  width: 100%;
  height: 100%;
  max-height: 349px;
  max-width: 326px;
  min-height: 340px;
  min-width: 320px;
  background-color: #82828A;
  border-radius: 10px;
  box-shadow: 0px 12px 8px -12px #AEFF8C, 0px 28px 10px -16px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
}
.section-six .visitbox .background{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 94.21%); 
  width: 100%;
  padding: 24px;
  border-radius: 10px;
  text-align: center;
  bottom: 0;
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 700;
}
.section-six .visitbox img{
   object-fit: cover;
   width: 100%;
   height: 100%;
   border-radius: 10px;
   position: absolute;  
}

/** medium screen **/
@media screen and (min-width: 768px){
  .heroBanner .bannerTitle h1{
    font-weight: 900;
    font-size: 64px;
    letter-spacing: -0.02em;
  }
  .heroBanner .bannerTitle p{
    font-weight: 400;
    font-size: 24px;
  }
  .section-five .videocontainer h2 {
    font-size: 48px;
  }
}

/** large screen **/
@media screen and (min-width: 1024px){
  .section-four .title {
    font-size: 40px;
  }
}