.header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgb(29, 35, 31, 0.96);
  }
  .header .menu img{
    width: auto;
    max-height: 56px;
    max-width: 180px;
  }
  .header .menu {
    border-bottom: 1px solid #1D231F;
    background:linear-gradient(rgba(255, 255, 255,0.04), rgba(255, 255, 255,0.08));
  }
  .header .menu .nav button{
    color: #FFFFFF;
    font-weight: 700;
    font-size: 20px;
  }
  .header .menu .nav button:hover{
    color: #63AB45;
  }
  .header .menu a{
    padding: 12px 24px;
    background: #63AB45;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 16px;
  }
  .header .menu a:hover{
    background: #FFFFFF;
    color:#63AB45;
  }

.header .md-menu{
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255,0.92);
  backdrop-filter: blur(4px);
  border-radius: 24px 24px 0 0;
  padding: 8px 32px 12px 32px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}
.header .md-menu .icon{
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .md-menu .icon a{
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #808080;
}
.header .md-menu .icon a:hover{
  color: #63AB45;
}
.header .md-menu .icon a > svg{
  width: 24px;
  height: 24px;
}
.header .md-menu .icon a > span{
  display: block;
  white-space: nowrap;
  font-size: 12px;
  text-transform: uppercase;
}

.header .md-menu .icon.main{
  width: 50px;
  height: 50px;
  position: relative;
}
.header .md-menu .icon.main > span{
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 50%;
  box-shadow: 0 -1px 2px rgba(0,0,0,0.20), 0 1px 2px rgba(0,0,0,0.20);
  border: 8px solid rgba(99, 171, 69, 0.90);
}

.header .md-menu .icon.main > span img{
  width: 40px;
  height: 40px;
}
  
/** large screen **/
@media screen and (min-width: 1024px){
  .header .menu a{
    padding: 16px 32px;
    background: #63AB45;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 18px;
  }
  .header .menu img{
    max-width: none;
  }
}